.IconRow {
    display: flex;
}

.Icon {
    width: 10vw;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addressFooter {
    padding-left: 20px;
    text-align: left;
    color: #d6442b;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 4.2vw;
}

.questions {
    padding-left: 20px;
    text-align: left;
    margin-top: 5px;
    color: black;
    width: 90%;
    font-size: 5.2vw;
    margin-bottom: 0;
}