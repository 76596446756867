@font-face {
  font-family: 'HoeflerBlackItalic';
  font-style: normal;
  font-weight: normal;
  src: local('HoeflerBlackItalic'), url('./assets/fonts/HoeflerBlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'WindsorProUltHv';
  font-style: normal;
  font-weight: normal;
  src: local('WindsorProUltHv'), url('./assets/fonts/WindsorProUltHv.TTF') format('truetype');
}

@font-face {
  font-family: 'Streetwear';
  font-style: normal;
  font-weight: normal;
  src: local('Streetwear'), url('./assets/fonts/Streetwear.otf') format('truetype');
}

body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7e0ce;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
