.App, .AppMobile {
  text-align: center;
  font-family: 'WindsorProUltHv';
  color: #d6442b;
}

.Row {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.Column {
  flex: 50%;
  margin-bottom: 40px;
}

.Logo {
  width: 90%;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.LogoFull {
  width: 60%;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Name {
  font-size: 10vw;
  text-align: left;
  text-transform: uppercase;
  padding-left: 40px;
  vertical-align: text-bottom;
}

.NameMobile {
  font-size: 12vw;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.Coming {
  text-align: left;
  font-size: 5vw;
  padding-left: 40px;
  margin-top: 0px;
}

.notification {
  font-size: 4vw;
  margin-bottom: -5px;
}

.OrderRow {
  margin-left: 3vw;
  margin-right: 3vw;
}

.OrderRowFlex {
  display: flex;
}


.OrderButton {
  width: 40vw;
  margin-left: 5vw;
}
.OrderButtonMobile {
  width: 80vw;
  margin-left: 10vw;
}

.orderText {
  font-size: 3vw;
  font-family: 'WindsorProUltHv';
  color: #d6442b;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-radius: 100px;
  padding-top: 2vw;
  padding-bottom: 2vw;
  margin-left: 1vw;
  margin-right: 1vw;
  box-shadow: 5px 5px black;
}

.orderText:hover {
  box-shadow: none;
}

.order-href {
  text-decoration: none;
}

.StarButton {
  flex: 10%;
}

.stars {
  filter: drop-shadow(6px 6px rgba(0, 0, 0, 1));
}

.stars:hover {
  filter: none;
}


.brunchStar {
  width: 30%;
  float: right;
  position: absolute;
  right: 0;
  filter: drop-shadow(6px 6px rgba(0, 0, 0, 1));
  z-index: 2;
}

.brunchStar:hover {
  filter: none;
}

.brunchStarDesktop {
  width: 15%;
  float: right;
  position: absolute;
  right: 0;
  margin-top: 10%;
  filter: drop-shadow(6px 6px rgba(0, 0, 0, 1));
  z-index: 2;
}

.brunchStarDesktop:hover {
  filter: none;
}
