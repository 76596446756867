.flex {
  display: flex;
}

.foundersImage {
  width: 40vw;
  margin-left: 5vw;
}

.closingTextImage {
  width: 90vw;
  margin-left: 5vw;
}

.thankYouText {
  width: 45vw;
  margin: 2vw 0 0 5vw;

  color: black;
  font-size: 3.5vw;
  text-align: left;
}

.closingBodyText {
  width: 70vw;
  margin: 6vw 0 0 5vw;

  color: black;
  font-size: 4vw;
  text-align: left;
}


.scheduleText {
  margin-top: 0vw;
  margin-left: 5vw;

  text-align: left;
  font-size: 3vw;
}

.suzyLogoImage {
  position: absolute;
  margin-top: -18vw;
  right: -6vw;
  width: 40vw;
  animation: rotate 3s infinite;
}

@keyframes rotate {
  0% {transform: rotate(0deg)}
  25% {transform: rotate(-10deg)}
  50% {transform: rotate(0deg)}
  75% {transform: rotate(10deg)}
  100% {transform: rotate(0deg)}
}