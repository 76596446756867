.scrollableContainerCounty {
    padding: 10px;
    overflow-x: scroll;
    display: grid;
    grid-auto-columns: 30%;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;
    text-align: left;
    margin-left: 5vw;
}

.scrollableContainerCountyMobile {
    padding: 10px;
    overflow-x: scroll;
    display: grid;
    grid-auto-columns: 60%;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;
    text-align: left;
    margin-left: 5vw;
}

.cardCounty {
    border: solid black;
    padding: 10px;
    border-radius: 25px;
    scroll-snap-align: center;
    transition: all 0.2s;
    font-family: 'HoeflerBlackItalic';
    color: black;
    align-items: flex-bottom;
    box-shadow: 10px 10px black;
}

.cardCounty:hover {
    box-shadow: none;
}

.scrollableContainerCounty::-webkit-scrollbar {
    height: 12px;
}

.mediaHeader {
    text-align: left;
    display: flex;
    width: 40%;
    margin-left: 5vw;
    margin-top: 2vw;
}

